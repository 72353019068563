import React, { useEffect,useState } from 'react'
import styled, { createGlobalStyle } from 'styled-components/macro'
import Header from '../components/AppBar'
import Sidebar from '../components/Sidebar'
import Settings from '../components/Settings'
import { GetCurrentUserInfo} from '../services/AdminServices/UserService'

import { spacing } from '@material-ui/system'
import {
  Hidden,
  CssBaseline,
  Paper as MuiPaper,
  withWidth
} from '@material-ui/core'

import { isWidthUp } from '@material-ui/core/withWidth'

const drawerWidth = 258

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${props => props.theme.palette.background.default};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`

const Drawer = styled.div`
   ${props => props.theme.breakpoints.up('md')} {
      width: ${drawerWidth}px;
      flex-shrink: 0;
   }
`

const AppContent = styled.div`
   flex: 1;
   display: flex;
   flex-direction: column;
`

const Paper = styled(MuiPaper)(spacing)

const MainContent = styled(Paper)`
   flex: 1;
   background: ${props => props.theme.palette.background.default};

   @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      flex: none;
   }

   .MuiPaper-root .MuiPaper-root {
      box-shadow: none;
   }
`

const getCurrentUser = async () => {
   // let result = (await GetCurrentUserInfo()).then(result => console.log(result));
    
   let result = (await GetCurrentUserInfo())
   //console.log(result.attributes);
   return result.attributes
 }

function MainLayout (props) {
    const [mobileOpen, setMobileOpen] = useState(false)
    const [currentUser, setCurrentUser] = useState({})

    const handleDrawerToggle = () => {
       setMobileOpen(!mobileOpen)
    }

    useEffect(async () => {     
      setCurrentUser(await getCurrentUser());
    }, []);
   //console.log(currentUser);

  return (
    <Root>
        <CssBaseline />
         <GlobalStyle />
         <Drawer>
         <Hidden mdUp implementation='js'>
               <Sidebar
                 
                  PaperProps={{ style: { width: drawerWidth } }}
                  variant='temporary'
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  currentUser={currentUser}
               />
            
            </Hidden>
            <Hidden smDown implementation='css'>
               <Sidebar
                 currentUser={currentUser}
                  PaperProps={{ style: { width: drawerWidth } }}
               />
             
            </Hidden>
         </Drawer>
         <AppContent>
            <Header onDrawerToggle={handleDrawerToggle} />
            <MainContent p={isWidthUp('lg', props.width) ? 12 : 5}>
               {props.children}
            </MainContent>
         </AppContent>         
    </Root>
  )
}

export default withWidth()(MainLayout)
