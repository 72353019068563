import { Auth, API, graphqlOperation } from 'aws-amplify'
// import { createHseCertification } from './../../graphql/mutations'

import {
  CognitoIdentityProviderClient,
  AdminCreateUserCommand,
  AdminGetUserCommand,
  AdminUpdateUserAttributesCommand,
  ListUsersCommand
} from '@aws-sdk/client-cognito-identity-provider'

import awsConfig from '../../aws-exports'

export async function GetUsers () {
  const client = new CognitoIdentityProviderClient({
    region: awsConfig.aws_cognito_region,
    credentials: Auth.currentCredentials()
  })  

  let UsersJson = []

  try {
    const data = await client
      .send(new ListUsersCommand({ UserPoolId: awsConfig.aws_user_pools_id , Filter: "status = \"Enabled\""}))
      .then(result => {
        let Users = result.Users
        const newUsers = Users.map(user => {
          //console.log( user.UserCreateDate);
          let newdata = user.Attributes.map(item => {
            return [item['Name'], item['Value']]
          })

          let tdata = Object.fromEntries(new Map(newdata))
          return {
            id: tdata['sub'],
            UserCreateDate: user.UserCreateDate,
            ...tdata
          }
        })

        UsersJson = JSON.parse(JSON.stringify(newUsers))
        //console.log('finalUsers: ' + UsersJson);
      })
  } catch (err) {
    console.error(err)
  }

  return UsersJson
  // return dummyUserData();
}

function dummyUserData () {
  return [
    {
      id: '203199a1-00d1-4a4b-8e7b-21ecb4226297',
      sub: '203199a1-00d1-4a4b-8e7b-21ecb4226297',
      email_verified: 'true',
      'custom:organisationName': 'Quanta Services Company',
      phone_number: '+610416634342',
      given_name: 'Praf',
      family_name: 'personal',
      email: 'prafullatandel@gmail.com'
    },
    {
      id: '9c31ab4a-0a3a-44da-bcb3-5f7ee1f0363a',
      sub: '9c31ab4a-0a3a-44da-bcb3-5f7ee1f0363a',
      email_verified: 'true',
      'custom:organisationName': 'Consolidated Power Projects',
      phone_number: '+610452552533',
      given_name: 'Praf',
      family_name: 'new',
      email: 'testing@gmail.com'
    },
    {
      id: 'e337554c-44e4-4448-9c11-925fb53f6b80',
      sub: 'e337554c-44e4-4448-9c11-925fb53f6b80',
      email_verified: 'true',
      phone_number_verified: 'false',
      phone_number: '+61416634342',
      given_name: 'Prafulla',
      family_name: 'Tandel',
      email: 'prafulla.tandel@digitly.com.au'
    }
  ]
}



export async function GetCurrentUserInfo(){
  let userData = {}
  await Auth.currentUserInfo().then((user ) => {
    userData = user;
    // console.log(user);
  })
  return userData;
}

export async function GetUser (userId) {
  const client = new CognitoIdentityProviderClient({
    region: awsConfig.aws_cognito_region,
    credentials: Auth.currentCredentials()
  })

  let params = {
    Username: userId,    
    UserPoolId: awsConfig.aws_user_pools_id
  }

  let userData = {}

  try {
     await client
      .send(new AdminGetUserCommand(params))
      .then(result => {

        if(result !== undefined) {

          let newdata = result.UserAttributes.map(item => {
            return [item['Name'], item['Value']]
          })

          
          userData = {            
            UserCreateDate: result.UserCreateDate,
            UserLastModifiedDate: result.UserLastModifiedDate,
            ...Object.fromEntries(new Map(newdata))
          }

          //console.log(userData);
        }
    });
 
    return userData;
  } catch (err) {
    console.error(err)
    return false
  }

}

export async function AdminCreateUser (userDetails) {
  //console.log('Admin create user called. for' + userDetails.given_name);

  const client = new CognitoIdentityProviderClient({
    region: awsConfig.aws_cognito_region,
    credentials: Auth.currentCredentials()
  })

  let params = {
    DesiredDeliveryMediums: ['EMAIL'],
    UserAttributes: [
      {
        Name: 'given_name',
        Value: userDetails.given_name
      },
      {
        Name: 'family_name',
        Value: userDetails.family_name
      },
      {
        Name: 'email',
        Value: userDetails.email
      },
      {
        Name: 'phone_number',
        Value: '+61' + userDetails.phone_number
      },
      {
        Name: 'email_verified',
        // Value: userDetails.email_verified
        Value: 'true'
      },
      {
        Name: 'custom:organisationName',
        Value: userDetails.organisation_name
        //Value: 'Quanta Services Company'
      }
    ],
    Username: userDetails.email,
    UserPoolId: awsConfig.aws_user_pools_id
  }

  try {
    const data = await client.send(new AdminCreateUserCommand(params))
    // console.log(data);
    return true
  } catch (err) {
    console.error(err)
    return false
  }
}

export async function UpdateUser (userDetails) {
  const client = new CognitoIdentityProviderClient({
    region: awsConfig.aws_cognito_region,
    credentials: Auth.currentCredentials()
  })

  let params = {
    UserAttributes: [
      {
        Name: 'given_name',
        Value: userDetails.given_name
      },
      {
        Name: 'family_name',
        Value: userDetails.family_name
      },
      {
        Name: 'phone_number',
        Value: userDetails.phone_number.includes("+61") ? (userDetails.phone_number) : ('+61' + userDetails.phone_number)
      }
    ],
    Username: userDetails.email,
    UserPoolId: awsConfig.aws_user_pools_id
  }

  try {
    const data = await client.send(new AdminUpdateUserAttributesCommand(params))
     console.log(data);
    return true;
  } catch (err) {
    console.error(err)
    return false
  }
}



