/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:63104fd1-ec8e-4142-b6ed-8adcc83c5207",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_N869VqNUR",
    "aws_user_pools_web_client_id": "25avulvt07k0o1589m09tvlhsf",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://3p2t5gdoz4.execute-api.us-east-2.amazonaws.com/dev",
            "region": "us-east-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://qdficaurqfhhvofaxqr63nqux4.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-wwhgeh7ekvhbdox5syrl5mlxxa",
    "aws_user_files_s3_bucket": "intellitrackhsefiles05413-dev",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
