import React from 'react'

import async from '../components/Async'

import { Home, BookOpen, Briefcase, Sliders, Users ,Award} from 'react-feather'

// Dashboards components
const Default = async(() => import('../pages/dashboards'))

const Projects = async(() => import('../pages/projects'))
const CreateProject = async(() => import('../pages/projects/CreateProject'))
const EditProject = async(() => import('../pages/projects/EditProject'))
const AssignUser = async(() => import('../pages/projects/AssignUser'))
const UserProfiles = async(() => import('../pages/userProfile'))
const CreateUser = async(() => import('../pages/userProfile/CreateUser'))
const EditUser = async(() => import('../pages/userProfile/EditUser'))
const Inductions = async(() => import('../pages/inductions'))
const createInduction = async(() => import('../pages/inductions/createInduction'))
const CreateRole = async(() => import('../pages/projects/CreateRole'))
const TestCode = async(() => import('../pages/userProfile/TestCode'))

const dashboardsRoutes = {
  id: 'Dashboard',
  path: '/',
  icon: <Home />,
  component: Default,
  children: null
}

const projectsRoutes = {
  id: 'Projects',
  path: '/projects',
  icon: <Briefcase />,
  component: Projects,
  children: null
}

const createProjectRoutes = {
  id: 'Projects',
  path: '/projects/CreateProject',
  component: CreateProject
}

const createRoleRoutes = {
  id: 'Projects',
  path: '/projects/CreateRole',
  component: CreateRole
}

const editProjectRoutes = {
  id: 'Projects',
  path: '/projects/EditProject/:projectId',
  component: EditProject
}

const assignUserRoutes = {
  id: 'Projects',
  path: '/projects/AssignUser/:projectId',
  component: AssignUser
}

const userRoutes = {
  id: 'UserProfiles',
  path: '/userProfiles',
  icon: <Users />,
  component: UserProfiles,
  children: null
}

const userProfileRoutes = {
  id: 'UserProfiles',
  path: '/userProfiles/CreateUser',
  component: CreateUser
}

const userProfileEditRoute = {
  id: 'UserProfiles',
  path: '/userProfiles/EditUser/:userId',
  component: EditUser
}

const inductionRoutes = {
  id: 'Inductions',
  path: '/inductions',
  icon: <Award />,
  component: Inductions,
  children: null
}
const createInductionRoutes = {
  id: 'createInduction',
  path: '/inductions/createInduction',
  component: createInduction
}
const userProfileTestRoute = {
  id: 'TestCode',
  path: '/userProfiles/TestCode',
  component: TestCode
}

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  dashboardsRoutes,
  projectsRoutes,
  createProjectRoutes,
  createRoleRoutes,
  editProjectRoutes,
  assignUserRoutes,
  userRoutes,
  userProfileRoutes,
  userProfileEditRoute,
  inductionRoutes,
  createInductionRoutes,
  userProfileTestRoute
]

// Routes visible in the sidebar
export const sidebarRoutes = [dashboardsRoutes, projectsRoutes, userRoutes,inductionRoutes]
