import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './App.css'
import {
  withAuthenticator,
  AmplifyAuthenticator,
  AmplifySignOut,
  AmplifySignUp,
  AmplifySignIn 
} from '@aws-amplify/ui-react'
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components'
import DateFnsUtils from '@date-io/date-fns'

import { ThemeProvider } from 'styled-components/macro'
import { create } from 'jss'
import { THEMES } from './constants';
import { setTheme } from "./redux/actions/themeActions";

import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
  jssPreset
} from '@material-ui/core/styles'

import createTheme from './theme'
import Routes from './routes/Routes'

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById('jss-insertion-point')
})

function App () {
  const theme = useSelector(state => state.themeReducer)
  const [authState, setAuthState] = useState()
  const [user, setUser] = useState()

  // const dispatch = useDispatch();
  // dispatch(setTheme(THEMES.DEFAULT))
  //const theme = "DEFAULT";

  React.useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState)
      setUser(authData)
    })
  }, [])

  return (
    <React.Fragment>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MuiThemeProvider theme={createTheme(theme.currentTheme)}>
            <ThemeProvider theme={createTheme(theme.currentTheme)}>
              {authState === AuthState.SignedIn && user ? (
                <div>
                  <Routes />
                  {/* <AmplifySignOut /> */}
                </div>
              ) : (
                <AmplifyAuthenticator>
                  <AmplifySignUp 
                    slot="sign-up"
                    usernameAlias="email"
                    formFields={[
                      {
                        type: "given_name",
                        label: "First Name *",
                        placeholder: "Enter First Name ",
                        required: true,
                      },
                      {
                        type: "family_name",
                        label: "Last Name *",
                        placeholder: "Enter Last Name ",
                        required: true,
                      },
                      {
                        type: "email",
                        label: "Email *",
                        placeholder: "custom email placeholder",
                        required: true,
                      },
                      {
                        type: "password",
                        label: "Password *",
                        placeholder: "custom password placeholder",
                        required: true,
                      },
                      {
                        type: "phone_number",
                        label: "Custom Phone Label",
                        placeholder: "custom Phone placeholder",
                        required: false,
                      },
                    ]}
                    />
                </AmplifyAuthenticator>
              )}
            </ThemeProvider>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </React.Fragment>
  )
}

export default App
